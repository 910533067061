/* global ScarabQueue */
import schttp from 'public/src/services/schttp'
import middleware from '../middleware'
import { pushCommandFlag, disabledPage } from './config'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

class EmarsysRecommend {
  constructor() {
    let el = document.createElement('div')
    el.setAttribute('id', 'j-recommend-product-list')
    document.body.appendChild(el)
    this.timer = null
  }
  async _init() {
    // 是否需要上传发emarsys的指令
    this.sendCommand = await pushCommandFlag()
    !this.sendCommand && (this.allowCommand = ['recommend', 'searchTerm', 'go'])
  }

  async logicsCommand (Logic) {
    // 1. 如果全都允许发送，返回
    if (this.sendCommand) return
    
    this.logicsCommands = []
    switch (Logic) {
      case 'related':
        this.logicsCommands = ['view']
        break
      case 'cart':
        this.logicsCommands = ['cart']
        await this.checkCart(true)
        break
      default:
        break
    }

  }

  async init() {
    const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'recommend/emarsys' }) ?? ''
    if (memberId) this.pushCommend('setCustomerId', memberId)

    const { pathname } = location
    const isInDisabledPage = disabledPage.find(v => v.test(pathname))
    if ( !isInDisabledPage ) {
      await this.checkCart()
    }
  }

  async checkCart() {
    let results = await schttp({
      url: '/api/cart/checkcart/get'
    })
    let cartItems = []
    if (results && results.info) {
      let carts = results.info.carts || []
      cartItems = carts.map(item => {
        return {
          item: item.product.goods_id,
          price: parseFloat(item.totalPrice.amount),
          quantity: parseFloat(item.quantity)
        }
      })
      // 代码迁移
      if ($('.j-header-cart-num').length) {
        if (results.info.cartSumQuantity > 0) {
          $('.j-header-cart-num').removeClass('mshe-hide').html(results.info.cartSumQuantity > 99 ? '99+' : results.info.cartSumQuantity)
        } else {
          $('.j-header-cart-num').addClass('mshe-hide').html(0)
        }
      }
    }
    this.pushCommend('cart', cartItems)
    return Promise.resolve()
  }
  /** 
   * 请求emarsys数据
  */
  fetchData(opts) {
    return new Promise((resolve, reject) => {
      let { container = 'j-recommend-product-list', logic = '', limit = 100, timeout = 0 } = opts || {}
      
      if (!document.getElementById(container) || !logic) {
        return reject(new Error('miss emarsys container or miss argument logic'))
      }
      let isFaultTolerance = null
      if (timeout > 0) {
        // fault tolerance
        setTimeout(() => {
          if (isFaultTolerance !== null) return
          isFaultTolerance = true
          console.log(logic, 'emarsys timeout callback')
          resolve({ SC: {}, isFaultTolerant: 1 })
        }, timeout)
      }

      this.pushCommend('recommend', {
        logic: logic,
        containerId: container,
        limit,
        success: (SC, render) => {
          if (isFaultTolerance !== null) return
          isFaultTolerance = false
          console.log(logic, 'emarsys success callback')
          resolve({ SC, render, isFaultTolerant: 0 })
        }
      })
      this.pushCommend('go')
    })
  }
  /** 
   * 处理emarsys数据
  */
  async getData(opts) {
    let { SC = {}, isFaultTolerant = 0 } = await this.fetchData(opts)
    let dataType = 'emarsys'
    let products = SC && SC.page && SC.page.products || []
    // format data
    products = middleware.adapter({ type: dataType, data: products })
    
    // filter current goods
    if(typeof _gb_app_ != 'undefined' && _gb_app_.$route.name == 'page_goods_detail') {
      products = middleware.filterCurrentItem({ goods_id: _gb_app_.$route.params[1] }, products)   
    }
    
    return Promise.resolve({
      products,
      dataType,
      isFaultTolerant
    })
  }
  setCommendView({ goodsId = '' } = {}) {
    if (!goodsId) return
    this.pushCommend('view', goodsId)
  }
  async pushCommend(commend, payload) {
    window.clearTimeout(this.timer)
    ScarabQueue.push([commend, payload])
    if (commend !== 'go') {
      this.timer = window.setTimeout(() => {
        this.pushCommend('go')
      }, 10000)
    } else {
      // go 完之后要清空之前临时允许的指令
      this.logicsCommands && this.logicsCommands.length && (this.logicsCommands = [])
    }
  }
}

let instance

// 首次执行时进行实例化, 后续调用直接返回实例
const EmarsysRecommendInner = () => {
  // 仅在服务端执行
  if (typeof window === 'undefined') {
    return {}
  }

  if (instance || window.emarsysInstance) {
    return instance || window.emarsysInstance
  }

  if (!instance) {
    instance = window.emarsysInstance = new EmarsysRecommend()
  }
  return instance
}

export default EmarsysRecommendInner
