/**
 * 根据商品项配置生成商品的原子请求字段信息（尽量考虑以后多场景用同一个方法生成，所以不要增加场景个性化的字段）
 * 维护时需同步服务端版本 services/product_atom/getAtomRequestFields.js
 * @param {Object} itemConfig 商品项的配置
 * @param {Array} filedList 额外自己控制的请求字段，不依赖商品项的？
 */
export const getAtomicRequestFileds = (itemConfig = {}, filedList = [], isRecommend = false) => {
  const { 
    showMultiColor, showMultiColorIcon, showPromotion, showSheinClubDiscountValue, showLeftStock, showPlusSize, 
    showUserAct, showCropImg, showLikeBtn, showSellingPoint, showSeriesBrand, showBadge, showFeedback, showSheinClubDiscountValueAfterPrice, showQuickShip, showNewFlashPrice, showNewFlashPriceOnSale, showNewFlashLabel, showPromoLabel, showBeltLabel, flashZonePromotion, showRankingList, showEstimateTag, showStarComment, showDecisionAttr, showUserBehaviorLabel, showEstimatedPriceReachThreshold, showUserPreferencesLabel,
    showHorseRaceLabel, showPriceDiscountDesc, showEstimatedPriceOnSale, showPropertyUpsell, showOneClickPayCard, showEstimatedPrice, showDeliveryWords, showSheinClubPriceOnSale
  } = itemConfig || {}
  const newFlashPromotion = [showNewFlashPrice, showNewFlashPriceOnSale, showNewFlashLabel].some(i => !!i)
  const listExtField = []
  if (showMultiColor || showMultiColorIcon) listExtField.push('relatedProducts')  // 多色块
  if (showPromotion || showSheinClubDiscountValue) listExtField.push('promotion') // 促销信息
  if (flashZonePromotion) listExtField.push('flashZonePromotion')  // 分人群专区闪购
  if (showLeftStock) listExtField.push('stock')                                   // 库存信息
  if (showUserAct) listExtField.push('tspLabels')                                 // tsp各种角标
  if (!showPlusSize) listExtField.push('plusSize')                                // 大尺码
  if (showCropImg || showOneClickPayCard) listExtField.push('realTimeTspLabels')                         // 裁剪需要传的原子服务字段
  if (showLikeBtn) listExtField.push('wish')                                      // 收藏
  if (showSellingPoint) listExtField.push('sellingPoints')                        // 卖点
  if (showSeriesBrand) listExtField.push('seriesAndBrand')                        // 品牌系列
  if (showBadge) listExtField.push('cccTspBadges')                                // ccc 角标
  if (showFeedback) listExtField.push('cccFeedback')                              // ccc 负反馈
  if (showSheinClubDiscountValue || showSheinClubDiscountValueAfterPrice || showSheinClubPriceOnSale) {       // 付费会员 | vip 价格
    listExtField.push('sheinClubPromotionInfo')
    listExtField.push('vipDiscountPrices')
  }   
  if (showEstimatedPrice) listExtField.push('estimatedPrice')  
  if (showQuickShip) listExtField.push('quickShip')                               // 快速发货标签
  if (newFlashPromotion) listExtField.push('newFlashPromotion')                   // 新型闪购
  if (showPromoLabel) listExtField.push('promoLabel')                             // 促销标签
  if (showRankingList) listExtField.push('rankingList')                           // 展示排行榜信息，注意需要在原子服务区分不同场景不同的poskey
  if (showBeltLabel) listExtField.push('beltLabel')                               // 腰带
  // if (showStarComment) listExtField.push('starComment') // 星级评论
  // if (showStarComment) listExtField.push(isRecommend ? 'recommendStarComment' : 'starComment') // 星级评论
  if (showStarComment) listExtField.push(isRecommend ? 'recommendStarComment' : 'starComment') // 星级评论
  if (showEstimateTag || showEstimatedPriceOnSale || showEstimatedPriceReachThreshold) listExtField.push('estimatedPrice') // 到手价
  if (showDecisionAttr) listExtField.push('enableDecisionAttr')                   // 角色属性
  if (showUserBehaviorLabel) listExtField.push('userBehaviorLabel')                    // 用户行为标签
  if (showUserPreferencesLabel) listExtField.push('userPreferences')                    // 用户偏好标签
  if (showOneClickPayCard) listExtField.push('oneClickPay')                    // 一键购  
  if (showPriceDiscountDesc) listExtField.push('priceDiscountDesc')               // 历史低价、保留款跟价款标签
  if (showHorseRaceLabel) listExtField.push('horseRaceLabel')               // 赛马标签
  if (showPropertyUpsell) listExtField.push('cornerPropertyUpsell')               // 属性类卖点标签
  if (showDeliveryWords) listExtField.push('deliveryInfo')               // 趋势标签

  // 请求字段的列表
  const requestMenuList = filedList.length ? [ ...new Set(listExtField.concat(filedList)) ] : listExtField
  // 请求的字段
  return requestMenuList.reduce((pre, curKey) => {
    if (curKey === 'tspLabels') {
      // 用户行为标签
      pre[curKey] = 'c7d_click_pv,c7d_wishlist_uv,c7d_sale_cnt,eval_cnt'
    } else if (curKey === 'realTimeTspLabels'){
      if(showOneClickPayCard) { // 一键购需要传TSP标签
        pre[curKey] = { label_cloud: ['60007129'] }
      } else {
        // 图片裁切
        pre[curKey] = { label_cloud: [] }
      }
    } else {
      pre[curKey] = true
    }
    return pre
  }, {})
}
